import React, {useState, useContext} from 'react';
import { styled } from '@mui/material/styles';
import { AldersgateContext } from '../../../context/aldersgate';
import TranslationText from '../../TranslationText';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CancelIcon from '@mui/icons-material/Cancel';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function UploadPeopleList(props) {
    const { closeModal, eventID, peopleList, setPeopleList } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [addedRows, setAddedRows] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const SetSelectedFile = (event) => {
        console.log(event.target.files);

        let file = event.target.files[0];

        if(file) {
            //ensure file is xlsx
            // if(file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            //     console.log("Invalid file type");
            //     return;
            // }

            const mimeTypes = /application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.ms-excel|application\/vnd.ms-office/;
            const mimetype = mimeTypes.test(file.type);
            
            //ensure file is xlsx
            if(!mimetype) {
                console.log("Invalid file type: " + file.type);
                setUploadError(true);
                return;
            }

            //ensure file is less than 5MB
            if(file.size > 5000000) {
                console.log("File is too large");
                setUploadError(true);
                return;
            }

            setSelectedFile(event.target.files[0]);
        }
        
    }


    const FileUpload = (event) => {

        let file = selectedFile;

        if(file) {

            const mimeTypes = /application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.ms-excel|application\/vnd.ms-office/;
            const mimetype = mimeTypes.test(file.type);
            
            //ensure file is xlsx
            if(!mimetype) {
                console.log("Invalid file type: " + file.type);
                setUploading(false);
                setUploadError(true);
                return;
            }

            //ensure file is less than 5MB
            if(file.size > 5000000) {
                console.log("File is too large");
                setUploading(false);
                setUploadError(true);
                return;
            }

            setUploading(true);

            console.log("file name: " + file.name);

            console.log("File: " + JSON.stringify(file, null, 2));
            

            //upload file
            let formData = new FormData();
            formData.append('file', file);
            formData.append('filename', file.name);

            console.log("Uploading file: " + JSON.stringify(formData, null, 2));

            aldersgateContext.PostToAPI('event/' + eventID + '/participants/upload', formData, null, 1, 'multipart/form-data').then((success, err) => {
                if(success) {
                    console.log("Success: " + JSON.stringify(success, null, 2));
                    //update the people list
                    let newPeopleList = [...peopleList];
                    if(success.length > 0) {
                        for(let i = 0; i < success.length; i++) {
                            newPeopleList.push(success[i]);
                        }
                    }

                    setAddedRows(success.length);
                    setUploading(false);
                    setUploadSuccess(true);

                    //wait 2 seconds before closing the modal
                    setTimeout(() => {
                        setPeopleList(newPeopleList);
                        closeModal();
                    }, 2000);
                }
            }
            ).catch((error) => {
                console.log("Error: " + error);
                setUploading(false);
                setUploadError(true);
            });

        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        let file = event.dataTransfer.files[0];

        if(file) {
            //ensure file is xlsx
            if(file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                console.log("Invalid file type");
                return;
            }

            //ensure file is less than 5MB
            if(file.size > 5000000) {
                console.log("File is too large");
                return;
            }

            setSelectedFile(file);
        }
    }

    const DisplayHeader = (
        <Box>
            <Typography variant="h6"><TranslationText page='tables' dbKey='uploadPeopleList'>Upload Student List</TranslationText></Typography>
            <Typography variant="body1"><TranslationText page='tables' dbKey='uploadPeopleListDesc'>Upload a list of students to the event roster</TranslationText></Typography>
            <ol>
                <li><Typography variant="body1"><TranslationText page='tables' dbKey='uploadPeopleListDesc1'>Download the Spreadsheet template</TranslationText></Typography></li>
                <li><Typography variant="body1"><TranslationText page='tables' dbKey='uploadPeopleListDesc2'>Add participant information</TranslationText></Typography></li>
                <li><Typography variant="body1"><TranslationText page='tables' dbKey='uploadPeopleListDesc'>Upload completed file</TranslationText></Typography></li>
            </ol>
        </Box>
    );

    if(uploadSuccess) {
        return (
            <Box>
                {DisplayHeader}

                <Box sx={{display:'flex', flexDirection:'row', gap:'5px'}}>
                    <CheckCircleIcon color='success' /> 
                    <Typography variant="body1"><TranslationText page='tables' dbKey='uploadSuccess'>Upload Successful</TranslationText></Typography>
                </Box>
                <Box>
                    <Typography variant="body1"><TranslationText page='tables' dbKey='addedRows'>{addedRows} students added to the list</TranslationText></Typography>
                </Box>
                <Box mt={2} sx={{display:'flex', flexDirection:'row', gap:'20px'}}>
                    <Button onClick={closeModal}><TranslationText page='tables' dbKey='close'>Close</TranslationText></Button>
                </Box>
            </Box>
        );
    }

    if(uploadError) {
        return (
            <Box>
                {DisplayHeader}
                <Box sx={{display:'flex', flexDirection:'column', gap:'20px'}}>
                    <Box sx={{display:'flex', flexDirection:'row', gap:'5px'}}>
                        <CancelIcon color='warning' /> 
                        <Typography variant="body1" color={'error'}><TranslationText page='tables' dbKey='uploadError'>There was an error uploading your list. Please use the provided .xlxs template file</TranslationText></Typography>
                    </Box>
                    <Box>
                        <Button variant="contained" fullWidth startIcon={<DownloadIcon />} href='https://iliteam.box.com/shared/static/luxt0vfkrqck0wwyumfmktdmaiiqp8u7.xlsx'>
                            <TranslationText page='tables' dbKey='downloadTemplate'>Download Spreadsheet Template</TranslationText>
                        </Button>
                    </Box>
                    <Box mt={2} sx={{display:'flex', flexDirection:'row', gap:'20px'}}>
                        <Button onClick={closeModal}><TranslationText page='tables' dbKey='close'>Close</TranslationText></Button>
                    </Box>
                </Box>
            </Box>
        );

    }


    return (
        <Box>
            
            {DisplayHeader}
            
            {selectedFile ? (
                <Box sx={{display:'flex', flexDirection:'column', gap:'5px'}}>
                    <Box sx={{display:'flex', flexDirection:'row', gap:'5px'}}>
                        <FilePresentIcon /> 
                        <Typography variant="body1">
                            {selectedFile.name}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Box
                        className="drop-zone"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}

                        sx={{width:'100%', minHeight:'150px', border:'2px dashed #ccc', bgcolor:'#efefef', borderRadius:'5px', display:'flex', justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}
                    >
                        <Box>
                            <Typography variant="body1">
                                <TranslationText page='tables' dbKey='dragDropFile'>Drag and drop a file here</TranslationText>
                            </Typography>
                        </Box>
                        <Box>
                            <Button component="label"
                                role={undefined}
                                
                                tabIndex={-1}
                                startIcon={<FileOpenIcon />}><TranslationText page='tables' dbKey='download'>Select File</TranslationText>
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={SetSelectedFile}
                                />
                            </Button>
                        </Box>

                    </Box>

                    <Box sx={{display:'flex', flexDirection:'column', gap:'20px', alignItems:'center'}}>
                        <Box>
                            <Typography variant="body1"><TranslationText page='tables' dbKey='uploadPeopleListNote'>Note: The file must be a copy of the roster spreadsheet template file and less than 5MB</TranslationText></Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" startIcon={<DownloadIcon />} href='https://iliteam.box.com/shared/static/luxt0vfkrqck0wwyumfmktdmaiiqp8u7.xlsx'>
                                <TranslationText page='tables' dbKey='downloadTemplate'>Download Spreadsheet Template</TranslationText>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}

            {selectedFile ? (
                <Box mt={2} sx={{display:'flex', flexDirection:'row', gap:'20px'}}>
                    <Button color="error" disabled={uploading} onClick={() => setSelectedFile(null)} startIcon={<CancelIcon />}><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                    <Button onClick={FileUpload} disabled={uploading}  startIcon={uploading ? <CircularProgress size="20px" /> : <CloudDownloadIcon />}><TranslationText page='tables' dbKey='upload'>Upload</TranslationText></Button>
                </Box>
                ) : (
                    <Box></Box>
                )
            }

        </Box>
    );
}