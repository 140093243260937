import React, {useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { AldersgateContext } from '../../../context/aldersgate';
import TranslationText from '../../TranslationText';
import {BtnBold, 
    BtnItalic, 
    BtnUnderline,
    BtnBulletList,
    BtnNumberedList,
    BtnLink,
    BtnRedo,
    BtnUndo,
    createButton, 
    Editor, 
    EditorProvider, 
    Separator,
    Toolbar} from 'react-simple-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import PersonIcon from '@mui/icons-material/Person';

export default function EditEventEmail(props) {
    const { msgData, eventID, handleCancel, setSnackbarMessage } = props;
    const [changedMsg, setChangedMsg] = useState({commSubject:'', commBody:''});
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    let authContext = useContext(AuthContext);

    useEffect(() => {
        let msg = {...msgData.messageData};
        if(msg.commBody !== undefined && msg.commBody !== null && msg.commBody !== "")
            msg.commBody = msg.commBody.replace(/(<br>|<br \/>)/g, '\n');

        setChangedMsg(prev => {
            
            if(msg.commSubject === undefined || msg.commSubject === null )
                msg.commSubject = "";
        
            if(msg.commBody === undefined || msg.commBody === null )
                msg.commBody = "";

            let newState = {...prev, ...msg};
            
            return newState;
        });

    }, []);

    useEffect(() => {
        let msg = {...msgData.messageData};
        if(msg.commSubject == undefined || msg.commSubject == null)
            msg.commSubject = "";

        if(msg.commBody == undefined || msg.commBody !== null)
            msg.commBody ="";

        if(changedMsg.commSubject !== msg.commSubject || changedMsg.commBody !== msg.commBody)
            setSaveBtnEnabled(true);
        else
            setSaveBtnEnabled(false);

    }, [changedMsg]);


    const handleChange = (event) => {
        const { id, value } = event.target;

        setChangedMsg(prevState => {
            let newState = {...prevState, [id]: value};
            return {...newState};
        });
    };

    const handleEditorChange = (event) => {
        const { value } = event.target;

        const cleanText = sanitizeHtml(value);

        setChangedMsg(prevState => {
            let newState = {...prevState, commBody: cleanText};
            return {...newState};
        });
    }

    const handleSave = () => {
        let messageData = {...changedMsg};

        console.log("account: " + JSON.stringify(authContext.adID, null, 4));

        messageData.userID = authContext.adID;

        //console.log("EditEventEmail msg: " + JSON.stringify(msgData, null, 4));

        aldersgateContext.PostToAPI('event/' + eventID + '/msgs', messageData, null, 1).then((success, err) => {
            if(success) {
                msgData.setMessageData(prevState => {
                    changedMsg.commBody = changedMsg.commBody.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    return {...prevState, onSignup:{...prevState.onSignup, ...changedMsg}};
                });
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Message Updated!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Message update error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Message update error!");
        });
    };

    const BtnAlignCenter = createButton('Align center', <FormatAlignCenterIcon />, 'justifyCenter');
    const BtnAlignLeft = createButton('Align left', <FormatAlignLeftIcon />, 'justifyLeft');
    const BtnAlignRight = createButton('Align right', <FormatAlignRightIcon />, 'justifyRight');
    const BtnName = createButton('Name', <PersonIcon />, ()=> {
        document.execCommand('insertText', false, '{FNAME}');
    })




    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField fullWidth size="small" label="Subject" id="commSubject" value={changedMsg.commSubject} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', height: 200, borderRadius: '5px', textAlign:'left' }}>

                <EditorProvider>
                    <Editor value={changedMsg.commBody} onChange={handleEditorChange} containerProps={{ style: { height:'100%', overflow:'scroll' } }} >
                        <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnUnderline />
                            <BtnBulletList />
                            <BtnNumberedList />
                            <BtnLink />
                            <Separator />
                            <BtnAlignLeft />
                            <BtnAlignCenter />
                            <BtnAlignRight />
                            <Separator />
                            <BtnName />
                        </Toolbar>
                    </Editor>
                </EditorProvider>
                    
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Button variant="contained" size="small" onClick={handleSave} disabled={!saveBtnEnabled} color="success"><TranslationText page='tables' dbKey='save'>Save</TranslationText></Button>
                    <Button variant="contained" size="small" onClick={handleCancel} color="error"><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                </Stack>
            </Grid>
        </Grid>
    );
}